.appHeader {
  background-color: rgb(0, 0, 0);
  position: sticky;  /* Make it sticky */
  top: 0;  /* Stick to the top */
  width: 100%;
  z-index: 9999;
  transition: background-color 0.3s ease, padding 0.3s ease, box-shadow 0.3s ease;
  padding: 20px 0;  /* Default padding */
}

.appHeader.scrolled {
  background-color: rgba(0, 0, 0, 0.9);  /* Darker background on scroll */
  padding: 5px 0;  /* Reduce padding on scroll */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);  /* Add shadow for depth */
}

.headerTop {
  display: flex;
  align-items: center;
  justify-content: right;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: white;
}
.navbar {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0px 4.5vw;
}
.navbar-left {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
}
.navbar-listContainer {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}
.navbar-list {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 0;
}
.navbar-item {
  margin-right: 40px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-top: 5px;
  justify-content: space-between;
}

.navbar-item a {
  display: inline;
}

.navbar-item:hover {
  border-top: 1px solid white;
  transition: 1s border-top linear;
}

.navbar-sidebar {
  display: none;
  align-items: center;
  justify-content: center;
}
.navbar-sidebar:hover {
  cursor: pointer;
}
.navbar-sidebar-drawer {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 70vw;
  height: 100vh;
  flex-direction: column;
  background-color: rgb(0, 0, 0);
}
.sidebar-drawer-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  font-weight: bold;
  font-size: 1.17em;
}
.sidebar-drawer-item:hover {
  border-top: 1px solid white;
  transition: 1s border-top linear;
}

/*MediaQuery pour afficher le Drawer*/
@media screen and (max-width: 800px) {
  .navbar-left {
    display: none;
  }
  .navbar-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100vw;
  }
}

.logo-container {
  width: 5.5vw; /* adjust as needed */
  height: 5vh;
  background-image: url("/logo.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/*--Modifier la dimension de l'icon*/
@media screen and (max-width: 900px) {
  .logo-container {
    width: 18vw;
  }
}

@keyframes infiniteScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.button-download {
  background: white;
  color: black;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.1);
  text-transform: none;
  animation: infiniteScale 1s infinite; /* Infinite scaling */
  transition: box-shadow 0.3s;
}

.button-download:hover {
  animation: none; /* Stop the animation on hover */
  opacity: 1;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.4); /* Optional: Change shadow on hover */
}
